import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"

export default function () {
  return (
    <Layout>
      <Seo title="2023 Mensajes" />
      <h1>2023 Mensajes</h1>
      <p>
        Abajo están los mensajes del 2023. Para DESCARGAR un mensaje haga CLIC
        DERECHO en enlace de descarga, y seleccione “Salvar destino como...”
        (Save Target As...) o “Salvar enlace como...” (Save Link como...),
        dependiendo del navegador que use. Para ESCUCHAR, sólo haga clic en el
        enlace “escuchar”.
      </p>
      <h2>La Ley y La Gracia - Jason Henderson</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>25/08/23</td>
            <td>Jason Henderson</td>
            <td>La Ley y La Gracia - parte 1</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/1ca66df0-4383-11ee-82b2-d53af1858037-Ley-y-Gracia-01.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/la-ley-y-la-gracia-pt-1">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>12/09/23</td>
            <td>Jason Henderson</td>
            <td>La Ley y La Gracia - parte 2</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/4c9e8e50-51a4-11ee-a06d-a31b04d2d095-Ley-y-Gracia-02.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/ley-y-gracia-pt-2">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>28/09/23</td>
            <td>Jason Henderson</td>
            <td>La Ley y La Gracia - parte 3</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/15e2ef60-6161-11ee-b975-cb0cfadd93ad-Ley-y-Gracia-03.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/ley-y-gracia-pt-3">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>23/10/23</td>
            <td>Jason Henderson</td>
            <td>La Ley y La Gracia - parte 4</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/04c08db0-71c8-11ee-b27c-e13e14dddc51-Ley-y-Gracia-04.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/ley-y-gracia-pt-4">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/08/23</td>
            <td>Jason Henderson</td>
            <td>La Ley y La Gracia - parte 5</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/bfb38950-7f11-11ee-962a-5d7b9c281fe2-Ley-y-Gracia-05.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/ley-y-gracia-pt-5">
                  texto
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>El Evangelio - Jason Henderson</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>14/07/23</td>
            <td>Jason Henderson</td>
            <td>El Evangelio - parte 1</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/574135f0-227a-11ee-a19d-717742939f83-01-El-Evangelio.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/el-evangelio-pt-1">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>21/07/23</td>
            <td>Jason Henderson</td>
            <td>El Evangelio - parte 2</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/9d78e210-27d0-11ee-a19d-717742939f83-02-El-Evangelio.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/el-evangelio-pt-2">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>28/07/23</td>
            <td>Jason Henderson</td>
            <td>El Evangelio - parte 3</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/16872970-2d7e-11ee-a19d-717742939f83-03-El-Evangelio.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/el-evangelio-pt-3">
                  texto
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>Andar en el Espíritu - Jason Henderson</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td>06/05/23</td>
            <td>Jason Henderson</td>
            <td>Andar en el Espíritu - parte 1</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/28b6ee40-03c2-11ee-aad5-eb2afe6580b1-Andar-en-el-Espiritu-1.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/andar-en-el-espiritu-pt-1">
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>07/06/23</td>
            <td>Jason Henderson</td>
            <td>Andar en el Espíritu - parte 2</td>
            <td>
              <div align="center">
                <a href="https://cdn.cosmicjs.com/af057fa0-1c3d-11ee-a19d-717742939f83-Caminar-en-el-Espiritu-2.mp3">
                  audio
                </a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href="https://hender.blog/publicaciones/andar-en-el-espiritu-pt-2">
                  texto
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  )
}
